import { reactive, ref } from "vue";
import useMessage from "@/hooks/useMessage";
import config from "../../config";
import { paidList } from "../../api/info/payRecordApi";
const usePayRecord = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);

  const orderTypes = ref([
    { value: 0, label: "普通订单" },
    { value: 1, label: "免费-错误免单" },
    { value: 2, label: "免费-特殊车辆" },
    { value: 3, label: "免费-免费时段" },
  ]);

  const parkingRecordVo = reactive({
    parkingLotId: "",
    parkingLotName: "",
    slotNum: "",
    plateNumber: "",
    inTime: "",
    outTime: "",
    state: "",
    parkingTime: "",
    phone: "",
    nickName: "",
    payState: "",
    shouldPayAmount: "",
    discountAmount: "",
    couponValue: "",
    refundState: "",
    refundCreatedBy: "",
    paidAmount: "",
    snapPics: "",
    inPic: "",
    outPic: "",
  });

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "订单号",
      align: "center",
      dataIndex: "id",
    },
    {
      title: "车场名称",
      align: "center",
      dataIndex: "parkingLotName",
    },
    {
      title: "泊位号",
      align: "center",
      dataIndex: "slotNum",
    },
    {
      title: "车牌号",
      align: "center",
      dataIndex: "plateNumber",
      slots: { customRender: "plateNumber" },
    },
  {
      title: "进场时间",
      align: "center",
      width: "160px",
      dataIndex: "inTime",
    },
    {
      title: "离场时间",
      align: "center",
      width: "160px",
      dataIndex: "outTime",
    },
/*
   {
     title: "停车时长",
     align: "center",
     dataIndex: "parkingTime",
     slots: { customRender: "parkingTime" },
   },
   {
     title: "应付金额（元）",
     align: "center",
     dataIndex: "shouldPayAmount",
     slots: { customRender: "shouldPayAmount" },
   },*/
    {
      title: "应付金额（元）",
      align: "center",
      dataIndex: "shouldPayAmount",
      slots: { customRender: "shouldPayAmount" },
    },
    {
      title: "实付金额（元）",
      align: "center",
      dataIndex: "paidAmount",
      slots: { customRender: "paidAmount" },
    },
    // {
    //   title: "优惠金额（元）",
    //   align: "center",
    //   dataIndex: "discountAmount",
    //   slots: { customRender: "discountAmount" },
    // },
    // {
    //   title: "支付方式",
    //   align: "center",
    //   dataIndex: "payChannel",
    //   slots: { customRender: "payChannel" },
    // },
    {
      title: "支付状态",
      align: "center",
      dataIndex: "payState",
      slots: { customRender: "payState" },
    },
    {
      title: "订单类型",
      align: "center",
      dataIndex: "orderType",
      slots: { customRender: "orderType" },
    },
    {
      title: "支付时间",
      align: "center",
      width: "160px",
      dataIndex: "payTime",
    },
    {
      title: "退款状态",
      align: "center",
      dataIndex: "refundState",
      slots: { customRender: "refundState" },
    },
    {
      title: "退款操作人",
      align: "center",
      dataIndex: "refundCreatedBy",
      slots: { customRender: "refundCreatedBy" },
    },
    {
      title: "操作",
      align: "center",
      slots: { customRender: "operation" },
    },
  ];
  const money = m => {
    if (typeof m !== "undefined") {
      return (m / 100).toFixed(2);
    }
    return "";
  };
  const getParkingTime = t => {
    if (typeof t !== "undefined") {
      var h = ((t - (t % 3600)) / 3600).toFixed(0);
      var s = ((t % 3600) / 60).toFixed(0);
      return h == 0 ? s + "分钟" : h + "小时" + (s == 0 ? "" : s + "分钟");
    }
    return "";
  };
  const getChannel = type => {
    switch (type) {
      case config.payChannel.weChat:
        return "微信";
      case config.payChannel.Alipay:
        return "支付宝";
      case config.payChannel.UnionPay:
        return "银联";
      case config.payChannel.cash:
        return "现金";
      case config.payChannel.other:
        return "其他";
      default:
        return "其他";
    }
  };
  const loadData = async (page, pageSize) => {
    loading.value = true;
    let p = {
      parkingLotId: params.parkingLotId,
      plateNumber: params.plateNumber,
      payOrderId: params.payOrderId,
      slotNum: params.slotNum,
      orderType: params.orderType,
      payTimeArr: params.payTimeArr ? params.payTimeArr.toString() : [],
    };
    try {
      let res = await paidList(p, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const params = reactive({
    payOrderId: "",
    parkingLotId: null,
    orderType: null,
    plateNumber: "",
    slotNum: "",
    payTimeArr: [],
  });
  return {
    loading,
    params,
    dataList,
    total,
    parkingRecordVo,
    columns,
    loadData,
    money,
    getParkingTime,
    getChannel,
    orderTypes,
  };
};
export default usePayRecord;
